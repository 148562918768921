import PropTypes from 'prop-types'
import React from 'react'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="kurumsal"
          className={`${this.props.article === 'kurumsal' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Kurumsal</h2>
          <h3>Biz Kimiz</h3>
          <p>1972 yılında abuhinda ismiyle Suriye de ithalat ve ihracat işleriyle ticari hayatımıza başladık. Ticaretimiz ortadoğu ülkeleriyle hızla gelişti. Speedex ünvanı ile 2002 yılında yeni bir lojistik firması kurup dünya ülkelerine ihracatımızı arttırdık.</p>
          <p>2009 yılında İstanbul Osmanbey merkezli MİRATEKS´i kurduk. MİRATEKS yurtiçi ve yurtdışında, imalat, stok yapısıyla, hızlı servis ve müşteri memnuniyetini hedeflemiş ve prensip haline getirmiştir.</p>
          <p>Zaman içinde değişen piyasa şartlarına uyum sağlayarak koruyan ve adapte olma başarısını gösteren MİRATEKS birçok konuda kendini yenilemiş ve standartlarını yükseltmeye devam etmiştir. Söz konusu pazar içerisinde yine birçok kurumsal firma ile dolaylı olarak üretim yaparak, ülkemize ve sektöre katkıda bulunmaya devam etmektedir.</p>
          <p>MİRATEKS; üretim bantlarında özellikle naylon, polyester, kapitoneli, örme, karışımlı, gömlek kumaş, laminasyonlu ve astar guruplarına ait ürünleri itina ile üretmekle beraber, zamanında teslimat konusunda da iddialıdır.</p>
          <p>MİRATEKSTİL siz değerli müşterilerimizle tecrübe ve teknolojiyi birleştirerek sektörü daha ileriye taşımayı amaçlamaktadır.</p>

          <h3>Vizyonumuz</h3>
          <p>Amacımız kaliteli üretim ile birlikte, en iyi maliyet ve fiyatla tecrübelerimizi birleştirerek müşteri memnuniyetine ulaşmaktır.</p>
          {close}
        </article>

        <article
          id="kalite-politikamiz"
          className={`${this.props.article === 'kalite-politikamiz' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Kalite Politikamız</h2>
          <p>
            Müşteri Memnuniyeti, Kalite, fiyat ve hız konularında müşteri memnuniyeti sağlamak.
          </p>
          {close}
        </article>

        <article
          id="iletisim"
          className={`${this.props.article === 'iletisim' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Bize Ulaşın</h2>
          <h3>İstanbul Merkez</h3>
          <p>Şair Nigar Sok. No.41 Kat:5 Osmanbey / Şişli / İSTANBUL</p>
          <p>Tel: <a href="tel:+90 212 219 99 95">+90 (212) 219 99 95</a>
          <br />Fax: <a href="tel:+90 212 219 97 75">+90 (212) 219 97 75</a>
          <br />E-mail: <a href="mailto:info@miratekstekstil.com">info@miratekstekstil.com</a>
          </p>

          <h3>Bursa Şube</h3>
          <p>Yavuzselim Mah. Kulaber Cad. No.22 Kat:1 Yıldırım / Bursa / Turkey</p>
          <p>Tel: <a href="tel:+90 224 368 00 55">+90 (224) 368 00 55</a>
          <br />Fax: <a href="tel:+90 224 368 00 66">+90 (224) 368 00 66</a>
          </p>
          <ul className="icons">
            <li>
              <a href="https://www.facebook.com/mirateks" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
